import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Navigation from './Navigation';
import Main from './Main';

function App() {
  return (
      <Container fluid className='App'>
        <Navigation></Navigation>
        <Main></Main>
      </Container>
    );
}

export default App;
