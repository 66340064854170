import hero from'./static/IMG_2925.jpg';
import hero2 from './static/IMG_2924.jpg';
import { Col, Row } from 'react-bootstrap';
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io";
import { FaSquareGit } from "react-icons/fa6";


import  './Main.styles.css';
const Main = ()=>{
    return(
        <main className='mjt-main'>
          <Row className='hero'>
            <Col>
            <div className='d-none d-lg-block d-md-block '><img src={hero2} width={'100%'} ></img></div>
            <div className='d-lg-none d-md-none d-block hero-mobile' style={{backgroundImage:`url(${hero})`}}>
                <p className='hero-title'>Breath some life to your life..</p>
                <ul className='hero-social-media-links'>
                    <li><a href='https://www.instagram.com/midhunjohnthomas' className='link-light'><AiFillInstagram></AiFillInstagram></a></li>
                    <li><a href='https://www.youtube.com/@midhunjohnthomas' className='link-light'><IoLogoYoutube></IoLogoYoutube></a></li>
                    <li><a href='https://github.com/midhunjthomas' className='link-light'><FaSquareGit></FaSquareGit></a></li>
                    
                </ul>
            </div>
                        </Col>
            </Row>
        </main>
    )
}
export default Main;